<template>
    <div class="invitationCode" :style="invitationCode">
        <common-nav-bar title="我的邀请码"></common-nav-bar>
        <div class="invitationTop">
            <div class="invitaitonTitle">关注获得免费健康服务</div>
            <div class="yierCode textC" :style="yierCode">
                <qrcode-vue :size="80" :value="qrcode"></qrcode-vue>
            </div>
        </div>
        <van-cell-group inset class="">
            <van-cell center class="userInfoTitle" :title="user.name">
                <template #icon>
                    <van-image :src="user.wxHeadimgurl || userPhoto" width="48" />
                </template>
                <template #right-icon>
                    <van-icon v-if="user.sex === 1" :name="sexNan" class="sex-icon" />
                    <van-icon v-if="user.sex === 2" :name="sexNv" class="sex-icon" />
                </template>
            </van-cell>
        </van-cell-group>
        <van-cell-group inset class="mt30 bgNo bottomLineNo">
            <van-cell class="textC bgNo" size="lager">
                <template #title>
                    <van-button
                        class="newBook plr40"
                        to="/patient/my/myFriend"
                        color="#4AB8AB"
                        size="normal"
                        >我邀请的朋友
                    </van-button>
                </template>
            </van-cell>
        </van-cell-group>
    </div>
</template>

<script>
import { getUser } from '@/services/user';
import { toast } from '@/util';
import qrcodeVue from 'qrcode-vue';
import CommonNavBar from '@/components/common-nav-bar';
export default {
    // name: "index"
    components: {
        CommonNavBar,
        qrcodeVue,
    },
    data() {
        return {
            user: {},
            qrcode: '',
            userPhoto: require('@/assets/images/userPhoto.png'),
            userDefault: require('@/assets/images/userDefault.png'),
            sexNan: require('@/assets/images/nan.png'),
            sexNv: require('@/assets/images/nv.png'),
            invitationCode: {
                backgroundImage: 'url(' + require('@/assets/images/myBgReverse.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
            },
            yierCode: {
                backgroundImage: 'url(' + require('@/assets/images/yierCode.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                height: '100%',
                boxSize: 'border-box',
                padding: '30px 0',
            },
        };
    },
    async created() {
        await this.getUser();
        this.genQrcode();
    },
    methods: {
        async getUser() {
            const { code, message, data } = await getUser();
            if (!code) {
                toast(message);
            }
            this.user = data;
        },
        genQrcode() {
            this.qrcode = location.origin + '?invitorId=' + localStorage.getItem('userId');
        },
    },
};
</script>

<style scoped>
.invitationTop {
    padding: 20px 16px 5px 16px;
}

.invitaitonTitle {
    text-align: center;
    font-size: 20px;
    color: #00c3ae;
    background: #fff;
}

.invitaitonTop .van-image {
    margin: 0 10px;
}

.userInfoTitle .van-image {
    font-size: 48px;
    height: auto;
    line-height: 100%;
    padding-right: 10px;
    border-radius: 5px;
}
.userInfoTitle .van-cell__left-icon {
    font-size: 48px;
    height: auto;
    line-height: 100%;
}

.sex-icon {
    font-size: 24px;
}
.bottomLineNo::after {
    display: none;
}
</style>
